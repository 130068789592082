import React, { useState } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import contactStyle from '../components/styles/contact.module.css'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Helmet from 'react-helmet'
import axios from "axios"
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3"

const Button = styled.button`
min-width: 80px;
padding: 10px 32px;
border-radius: 4px;
border: none;
background: #141414;
color: #fff;
font-size: 12px;
cursor: pointer;
`

const Contact = ({ data }) => {
    const { allContentfulContactPage: { nodes: contact },
    } = data

    const [token, setToken] = useState()

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    const data = new FormData(form)
    data.append("g-recaptcha-response", token)

    console.log(data, "data")
    axios({
      method: "post",
      url: "https://getform.io/f/40d8cf91-cb65-4812-9be3-1f70f4de1d5b",
      data
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact - Neferka</title>
                <link rel="canonical" href="http://neferka.design/contact" />
            </Helmet>
            
            <div className={contactStyle.row + ' ' + contactStyle.center}>
                {contact.map((item) => {
                    return (
                        <div className={contactStyle.col + ' ' + contactStyle.colspan3}>
                            <h3 style={{fontSize: '24px', fontWeight:'400'}}>
                                {item.title}
                                {/* {documentToReactComponents(JSON.parse(item.mainText.raw))} */}
                            </h3>
                            <p>
                                {documentToReactComponents(JSON.parse(item.mainText.raw))}
                            </p>
                        </div>
                    )
                })}
                <div className={contactStyle.col + ' ' + contactStyle.colspan3}>
                <GoogleReCaptchaProvider reCaptchaKey="6LfBygEdAAAAADAvsCSjKTP_pZ-B3hjO9bKwDZNF">
                    <form onSubmit={handleOnSubmit}>

                        <label>
                            Name
    <input type="text" name="name" id="name" />
                        </label>
                        <label>
                            Email
    <input type="email" name="email" id="email" />
                        </label>
                        <label>
                            Message
    <textarea name="message" id="message" rows="5" />
                        </label>
                        <div style={{
                            paddingLeft: '10px',
                            display: 'flex',
                            flexDirection:'row'
                        }}>
                            <div
                            style={{
                                paddingRight:'0.5rem'
                            }}
                            >
                                <Button type="submit">Send</Button>
                            </div>
                            <div>
                                <Button type="reset" value="Clear">Clear</Button>
                            </div>
                        </div>
                        <GoogleReCaptcha
              onVerify={token => {
                setToken(token)
              }}
            />
                    </form>
                    </GoogleReCaptchaProvider>
                </div>
            </div>

            

        </Layout>
    )
}

export const query = graphql`
{
    allContentfulContactPage {
        nodes {
          title
          mainText {
            raw
          }
        }
      }
}
`

export default Contact
